import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import flattenNodes from '../util/flattenNodes'
import Faq from '../components/Faq'
import { HTMLContent } from '../components/Content'

const FaqPage = ({data}) => {

  const faqs = flattenNodes(data.faqs)
  const content = data.page.html

  return (
    <Layout>
      <section className="page-content">
        <HTMLContent content={content} />
      </section>
      <section className="section">
        {faqs.map(faq => <Faq key={faq.id} {...faq} />)}
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($title: String!) {
    faqs: allMarkdownRemark(
      sort: { fields: [frontmatter___position], order: ASC }
      filter: { frontmatter: { key: { eq: "faq" }}}
    ) {
      edges {
        node {
          html
          id
          frontmatter {
            question
          }
        }
      }
    }
    page: markdownRemark(frontmatter: { title: { eq: $title }}) {
      html
      frontmatter {
        title
        metaKeywords
        metaDescription
      }
    }
  }
`

export default FaqPage
