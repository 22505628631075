import React from 'react'
import { HTMLContent } from '../components/Content'
import styles from '../components/Faq.module.css'

const Faq = ({question, html}) =>
  <div className={styles.pair}>
    <h4 className={styles.question}>{question}</h4>
    <HTMLContent className={styles.answer} content={html} />
  </div>

export default Faq
